import _objectSpread from "D:/Source/Repos/WallinServicesGui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
export default {
  name: "PlatformSelector",
  data: function data() {
    return {//
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    platforms: function platforms(state) {
      return state.platforms;
    }
  })), {}, {
    platformId: {
      get: function get() {
        return this.$store.state.currentPlatformId;
      },
      set: function set(platformId) {
        this.$store.dispatch("setCurrentPlatformId", platformId);
      }
    }
  })
};